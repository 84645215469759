const logotext = "FELIX";
const meta = {
    title: "Felix Nitz",
    description: "I’m Felix Nitz system -, data - and devops Engineer, currently working in Zurich",
};

const introdata = {
    title: "I’m Felix Nitz",
    animated: {
        first: "I boost your system",
        second: "I automate the boring stuff",
        third: "I love coding",
    },
    description: "'There is no reason not to follow your heart. ... Stay hungry. Stay foolish.'",
    your_img_url: "https://images.unsplash.com/photo-1719775928997-29bb08df569b",
};

const dataabout = {
    title: "About me",
    aboutme: "I am a sports enthusiast, nature lover and enjoy eating delicious food.",
};
const worktimeline = [{
        jobtitle: "Application Manager",
        where: "Zurich",    
        date: "2021-Today",
    },
    {
        jobtitle: "System Engineer",
        where: "Hamburg",
        date: "2018-2021",
    },
    {
        jobtitle: "Support Egineer",
        where: "Hamburg",
        date: "2014-2018",
    },
];

const skills = [{
        name: "Powershell",
        value: 90,
    },
    {
        name: "Bash",
        value: 90,
    },
    {
        name: "SQL",
        value: 85,
    },
    {
        name: "Python",
        value: 55,
    },
    {
        name: "C#",
        value: 65,
    },
    {
        name: "Javascript / HTML / CSS",
        value: 70,
    },
];

const services = [{
        title: "DevOps / CICD / Linux / Windows / Container",
        description: "Improving App- / Systemmanagement and Deployment",
    },
    {
        title: "Automation / Scripting",
        description: "Automate and Script things with ease to reduce effort",
    },
    {
        title: "Database Management SQL / NOSQL",
        description: "Manage and improce your DB System and Databases",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "info@nitz.de",
    description: "If you have questions or remarks just text me.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_zmqez3l",
    YOUR_TEMPLATE_ID: "template_tf10v38",
    YOUR_USER_ID: "nBZcb7OCh42S6E8S3",
};

const socialprofils = {
    github: "https://github.com/stpaulifan",
    linkedin: "https://www.linkedin.com/in/felix-nitz/",
    twitter: "https://x.com/StPauliFan1",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};